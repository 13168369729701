import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VImg } from 'vuetify/lib/components/VImg';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,[_c('div',{staticClass:"d-flex"},[_c('div',[_c(VCardTitle,{staticClass:"text-base font-weight-semibold pb-0"},[_c('p',{class:_vm.statistics <= 1000 ? 'red-text' : ''},[_vm._v(" "+_vm._s(_vm.statTitle)+" ")])]),_c(VCardText,{staticClass:"d-flex align-center"},[_c('p',{staticClass:"text-2xl font-weight-semibold py-0 mb-0",class:_vm.statistics <= 1000 ? 'red-text' : ''},[_vm._v(" "+_vm._s(_vm.statistics)+" ")])]),_c(VCardText,{style:(_vm.statistics > 1000 && 'padding-bottom: 47px !important;')},[(_vm.statistics <= 1000)?_c(VChip,{staticClass:"v-chip-light-bg font-weight-semibold",class:"error--text",attrs:{"small":"","color":_vm.chipColor}},[_vm._v(" "+_vm._s(_vm.chipText)+" ")]):_vm._e()],1)],1),_c(VSpacer),_c('div',{staticClass:"illustrator-img"},[_c(VImg,{attrs:{"width":_vm.avatarWidth,"src":_vm.statistics <= 1000?_vm.avatarRed:_vm.avatar}})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }