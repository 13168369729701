<template>
  <div>
    <v-row>
      <v-col cols="12" md="3" sm="6" order="1" class="align-self-end">
        <StatisticsCardWithImagesCustom
          :avatar="ratingsOptions.avatar"
          :avatarRed="ratingsOptions.avatarRed"
          :avatar-width="ratingsOptions.avatarWidth"
          :change="ratingsOptions.change"
          :chip-color="ratingsOptions.chipColor"
          :chip-text="ratingsOptions.chipText"
          :statistics="ratingsOptions.statistics"
          :stat-title="ratingsOptions.statTitle"
        ></StatisticsCardWithImagesCustom>
      </v-col>

      <v-col cols="12" md="3" sm="6" order="2" class="align-self-end">
        <statistics-card-with-images
          :avatar="sessionsOptions.avatar"
          :avatar-width="sessionsOptions.avatarWidth"
          :change="sessionsOptions.change"
          :chip-color="sessionsOptions.chipColor"
          :chip-text="sessionsOptions.chipText"
          :statistics="sessionsOptions.statistics"
          :stat-title="sessionsOptions.statTitle"
        ></statistics-card-with-images>
      </v-col>

      <v-col cols="12" md="6" sm="12" order="5" order-md="3">
        <crm-statistics-card></crm-statistics-card>
      </v-col>

      <v-col cols="12" md="3" sm="6" order="3" order-md="4">
        <crm-total-sales></crm-total-sales>
      </v-col>

      <v-col cols="12" md="3" sm="6" order="4" order-md="5">
        <crm-revenue-report></crm-revenue-report>
      </v-col>

      <v-col cols="12" md="6" sm="12" order="6">
        <crm-sales-overview></crm-sales-overview>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import StatisticsCardWithImages from '@core/components/statistics-card/StatisticsCardWithImages.vue'

// icons
import { mdiLabelVariantOutline } from '@mdi/js'

// demos

import CrmRevenueReport from './CrmRevenueReport.vue'
import CrmSalesOverview from './CrmSalesOverview.vue'
import CrmStatisticsCard from './CrmStatisticsCard.vue'
import CrmTotalSales from './CrmTotalSales.vue'
import store from '@/store'
import StatisticsCardWithImagesCustom from '@core/components/statistics-card/StatisticsCardWithImagesCustom.vue'

export default {
  components: {
    StatisticsCardWithImages,
    StatisticsCardWithImagesCustom,
    CrmStatisticsCard,
    CrmTotalSales,
    CrmRevenueReport,
    CrmSalesOverview,
  },
  setup() {
    // card with images
    const ratingsOptions = {
      statTitle: 'Remaining OTP',
      statistics: `${store.state.app.otp_remaining}`,
      chipText: 'Please Recharge the OTP',
      chipColor: 'primary',
      avatar: require('@/assets/gif/animation_640_le71mcex.gif'),
      avatarRed: require('@/assets/gif/animation_640_le71sz7w.gif'),
      avatarWidth: '100',
    }
    const sessionsOptions = {
      statTitle: 'Sessions',
      statistics: '24.5k',
      change: '-22%',
      chipText: 'Last Week',
      chipColor: 'error',
      avatar: require('@/assets/images/avatars/10.png'),
      avatarWidth: '86',
    }

    const growthAreaChart = {
      statTitle: 'Total Growth',
      statistics: '42.5k',
      series: [
        {
          name: 'Subscribers',
          data: [28, 40, 36, 52, 38, 60, 55],
        },
      ],
    }

    // vertical card options
    const newProjectOptions = {
      statTitle: 'New Project',
      icon: mdiLabelVariantOutline,
      color: 'primary',
      subtitle: 'Yearly Project',
      statistics: '862',
      change: '-18%',
    }

    return {
      ratingsOptions,
      sessionsOptions,
      growthAreaChart,
      newProjectOptions,
    }
  },
}
</script>
