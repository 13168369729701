<template>
  <v-card>
    <div class="d-flex">
      <div>
        <v-card-title class="text-base font-weight-semibold pb-0">
          <p :class="statistics <= 1000 ? 'red-text' : ''">
            {{ statTitle }}
          </p>
        </v-card-title>
        <v-card-text class="d-flex align-center">
          <p class="text-2xl font-weight-semibold py-0 mb-0" :class="statistics <= 1000 ? 'red-text' : ''">
            {{ statistics }}
          </p>
        </v-card-text>
        <v-card-text :style="statistics > 1000 && 'padding-bottom: 47px !important;'">
          <v-chip v-if="statistics <= 1000" small :color="chipColor" class="v-chip-light-bg font-weight-semibold" :class="`error--text`">
            {{ chipText }}
          </v-chip>
        </v-card-text>
      </div>
      <v-spacer></v-spacer>
      <div class="illustrator-img">
        <v-img :width="avatarWidth" :src="statistics <= 1000?avatarRed:avatar"></v-img>
      </div>
    </div>
  </v-card>
</template>

<script>
export default {
  props: {
    statTitle: { type: String, default: '' },
    statistics: { type: String, default: '' },
    chipText: { type: String, default: '' },
    chipColor: { type: String, default: '' },
    avatar: { type: String, default: '' },
    avatarRed: { type: String, default: '' },
    avatarWidth: { type: String, default: '' },
  },
  setup() {
    const checkChange = value => {
      const firstChar = value.charAt(0)
      if (firstChar === '+') {
        return true
      }

      return false
    }

    return { checkChange }
  },
}
</script>

<style lang="scss" scoped>
.illustrator-img {
  position: absolute;
  right: 5%;
  bottom: 1rem;
}

.v-application {
  &.v-application--is-rtl {
    .illustrator-img {
      left: 5%;
      right: unset;
    }
  }
}
.red-text {
  color: red !important;
}
</style>
